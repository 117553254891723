#output-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    row-gap: 1rem;
    align-items: stretch;
}

#processed-text {
    line-height: 2;
}

.controls-container {
    background-color: #f0e6d2;
    border: 2px solid #8b4513;
    border-radius: 8px;
    padding: 1rem;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.slider-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 1rem;
}

.slider-container label {
    margin-right: 0.5rem;
    white-space: nowrap;
}

.slider-container input[type="range"] {
    flex-grow: 1;
    max-width: 200px;
    margin-right: 0.5rem;
}

#frequency-value {
    white-space: nowrap;
}

.reset-button {
    background-color: #8b4513;
    color: #fff9e6;
    border: none;
    margin-left: auto;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reset-button:hover {
    background-color: #6b3410;
}

.text-container {
    flex-grow: 1;
    overflow-y: auto;
    background-color: #fff9e6;
    border: 2px solid #8b4513;
    border-radius: 8px;
    padding: 1rem;
}

@media (max-width: 768px) {
    .controls-container {
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 0;
    }

    .slider-container {
        margin-right: 0;
        flex-direction: column;
    }

    .slider-container label {
        margin: 0;
        white-space: unset;
    }

    .reset-button {
        width: 100%;
        margin: 0;
    }
}

.loading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-indicator p {
    margin-top: 1rem;
    font-family: 'Cinzel', serif;
    color: #8b4513;
}

#flow-feedback {
    flex-grow: 1;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
}

#flow-feedback.correct {
    color: #2e7d32;
    background-color: rgba(232, 245, 233, 0.8);
}

#flow-feedback.incorrect {
    color: #c62828;
    background-color: rgba(255, 235, 238, 0.8);
}

@media (max-width: 768px) {
    #flow-feedback {
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

#flash-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-grow: 1;
    margin-right: 1rem;
}

.flash-input {
    flex-grow: 1;
    height: 2.5rem;
    border: 2px solid #8b4513;
    border-radius: 4px;
    background-color: #fff9e6;
    color: #3c2f2f;
    font-size: 1rem;
    padding: 0 0.5rem;
}

.flash-submit,
.show-line-button {
    height: 2.5rem;
    background-color: #8b4513;
    color: #fff9e6;
    border: none;
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    padding: 0 1rem;
}

.flash-submit:hover,
.show-line-button:hover {
    background-color: #6b3410;
}

@media (max-width: 768px) {
    #flash-controls {
        flex-direction: column;
        align-items: stretch;
        margin-right: 0;
        margin-bottom: 1rem;
    }
}

#flow-progress-fill {
    height: 100%;
    background-color: #8b4513;
    width: 0;
    transition: width 0.3s ease;
}
