#input-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 2;
    justify-content: space-between;
}

#input-container textarea {
    padding: 1rem;
    height: 100%;
    margin-bottom: 1rem;
    border: 2px solid #8b4513;
    border-radius: 8px;
    background-color: #fff9e6;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    resize: vertical;
}

.slider-container {
    background-color: #d4c4a8;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.slider-container label {
    font-family: 'Cinzel', serif;
    color: #5c3d2e;
    font-weight: bold;
    display: block;
}

#word-frequency {
    -webkit-appearance: none;
    width: 100%;
    height: 10px;
    border-radius: 5px;
    background: #8b4513;
    outline: none;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

#word-frequency::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f0e6d2;
    cursor: pointer;
    border: 2px solid #8b4513;
    box-shadow: 0 0 0 3px #f0e6d2, 0 0 0 6px #8b4513;
}

#word-frequency::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #f0e6d2;
    cursor: pointer;
    border: 2px solid #8b4513;
    box-shadow: 0 0 0 3px #f0e6d2, 0 0 0 6px #8b4513;
}

#frequency-value {
    font-family: 'Cinzel', serif;
    font-weight: bold;
    color: #5c3d2e;
    font-size: 1.2rem;
    display: block;
    text-align: center;
}

.button-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.button-container button {
    flex: 1;
    min-width: 120px;
    white-space: nowrap;
}

@media (max-width: 600px) {
    .button-container {
        flex-direction: column;
    }

    .button-container button {
        width: 100%;
    }
}
