.flash-mode {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    font-family: 'Lato', sans-serif;
    overflow-y: auto;
}

.completed-poem {
    flex: 0 0 auto;
    width: 100%;
    text-align: center;     
    background-color: #fff9e6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.completed-poem p {
    margin: 0.5rem;
    color: #3c2f2f;
}

.flash-display {
    flex: 2 0 auto;
    background-color: #fff9e6;
    border-radius: 8px;
    margin-bottom: 1rem;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.flash-line {
    font-size: 1.2rem;
    text-align: center;
    color: #3c2f2f;
    margin: 1rem;
    margin-top: 0rem;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.flash-line.visible {
    opacity: 1;
}

.flash-controls {
    flex: 1 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    transition: background-color 0.3s ease;
    border-radius: 8px;
}

.flash-mode.correct {
    background-color: rgba(0, 128, 0, 0.2);
    animation: flash-green 1s;
}

.flash-mode.wrong {
    background-color: rgba(255, 0, 0, 0.2);
    animation: flash-red 1s;
}

@keyframes flash-green {
    0% { background-color: rgba(0, 128, 0, 0.2); }
    50% { background-color: rgba(0, 128, 0, 0.5); }
    100% { background-color: rgba(0, 128, 0, 0.2); }
}

@keyframes flash-red {
    0% { background-color: rgba(255, 0, 0, 0.2); }
    50% { background-color: rgba(255, 0, 0, 0.5); }
    100% { background-color: rgba(255, 0, 0, 0.2); }
}

.flash-input {
    flex-grow: 1;
    height: 2.5rem;
    border: 2px solid #8b4513;
    border-radius: 4px;
    background-color: #fff9e6;
    color: #3c2f2f;
    font-size: 1rem;
    padding: 0 0.5rem;
}

.flash-submit,
.show-line-button {
    height: 2.5rem;
    background-color: #8b4513;
    color: #fff9e6;
    border: none;
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    padding: 0 1rem;
}

.flash-submit:hover,
.show-line-button:hover {
    background-color: #6b3410;
}

.flash-feedback {
    font-weight: bold;
    color: #8b4513;
    white-space: nowrap;
}

.flash-progress {
    font-family: 'Cinzel', serif;
    color: #8b4513;
    margin: 1rem;
    display: flex;
    justify-content: center;
}

.flash-mode-complete {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flash-mode-complete h2 {
    font-family: 'Cinzel', serif;
    font-size: 1.5rem;
    color: #8b4513;
    margin-bottom: 1rem;
}

@media (max-width: 768px) {
    .flash-controls {
        flex-direction: column;
        align-items: stretch;
    }

    .flash-input,
    .flash-submit,
    .show-line-button {

    }

    .flash-feedback {
        text-align: center;
        margin-top: 0.5rem;
    }
}
