.random-poem {
    background-color: #f0e6d2;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%238b4513' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    border: 2px solid #8b4513;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    padding: 1rem;
}

.random-poem-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    row-gap: 1rem;
}

.random-poem button {
    width: 200px;
    background-color: #8b4513;
    color: #fff9e6;
    border: none;
    padding: 0.75rem 1.5rem;
    font-family: 'Cinzel', serif;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
    flex: 0 0 auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}

.random-poem button:hover {
    background-color: #6b3410;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.random-poem button:disabled {
    background-color: #d4c4a8;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.poem-info {
    font-family: 'Lato', sans-serif;
    flex: 1 1 auto;
    margin-left: 2rem;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.poem-info p {
    margin: 0.25rem 0;
    color: #5c3d2e;
}

.poem-info .poem-title {
    font-family: 'Cinzel', serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: #8b4513;
}

.poem-info .poem-author {
    font-style: italic;
}

.poem-placeholder {
    font-style: italic;
    color: #8b4513;
}

@media (max-width: 768px) {
    .random-poem-content {
        flex-direction: column-reverse;
        align-items: stretch;
    }

    .random-poem button {
        width: 100%;
    }

    .poem-info {
        margin-left: 0;
        text-align: center;
    }
}
