@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&family=Lato&display=swap');

:root {
    font-size: 16px;
}

h1, h2, p {
    margin: 0;
}

body, html {
    position:absolute;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    font-family: 'Lato', sans-serif;
    background-color: #f0e6d2;
    color: #3c2f2f;
    line-height: 1.6;
}

.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem;
    row-gap: 1rem;  
    margin: auto; 
    max-width: 800px; 
    justify-content: space-between;  
    display: flex;
    flex-direction: column;
}

h1 {
    font-family: 'Cinzel', serif;
    font-size: 2.5rem;
    margin-bottom: 0;
    text-align: center;
    color: #8b4513;
}

button {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    background-color: #8b4513;
    color: #fff9e6;
    border: none;
    border-radius: 4px;
    font-family: 'Cinzel', serif;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #6b3410;
}

@media (max-width: 768px) {
    :root {
        font-size: 15px;
    }
    h1 {
        font-size: 2rem;
    }
}

@media (max-width: 300px) {
    :root {
        font-size: 14px;
    }
}


@supports (-webkit-touch-callout: none) {
    .container {
      height: -webkit-fill-available; /* Fallback for older iOS versions */
      height: 100dvh; /* Use dynamic viewport height for newer versions */
    }
  }
