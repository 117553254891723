.flow-mode {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-family: 'Lato', sans-serif;
    background-color: #f0e6d2;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%238b4513' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.flow-mode > * {
    margin: 1rem;
}

#flow-progress {
    height: 10px;
    background-color: #d4c4a8;
    border-radius: 5px;
    overflow: hidden;
}

#flow-progress-fill {
    height: 100%;
    background-color: #8b4513;
    width: 0;
    transition: width 0.3s ease;
}

#flow-input {
    flex-grow: 1;
    min-height: 150px;
    border: 2px solid #8b4513;
    border-radius: 8px;
    background-color: rgba(255, 249, 230, 0.8);
    font-family: 'Lato', sans-serif;
    font-size: 1.1rem;
    resize: none;
    transition: border-color 0.3s ease, box-shadow 03s ease;
    padding: 1rem;
    overflow-y: auto;
}

#flow-input:focus {
    outline: none;
    border-color: #6b3410;
    box-shadow: 0 0 0 2px rgba(107, 52, 16, 0.2);
}

#flow-feedback {
    height: 1.5rem;
    font-size: 1rem;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 1rem;
}

#flow-feedback.correct {
    color: #2e7d32;
    background-color: rgba(232, 245, 233, 0.8);
}

#flow-feedback.incorrect {
    color: #c62828;
    background-color: rgba(255, 235, 238, 0.8);
}

.highlight-wrong {
    background-color: rgba(255, 235, 238, 0.8) !important;
    border-color: #c62828 !important;
}

@media (max-width: 768px) {
    .flow-mode > * {
        margin: 0.75rem;
    }

    #flow-input {
        font-size: 1rem;
    }
}
