.word-input-container {
    position: relative;
    display: inline-block;
    margin: 0 0.25rem;
}

.word-input {
    width: auto;
    min-width: 60px;
    max-width: 200px;
    display: inline-block;
    font-size: 1rem;
    padding: 0.2rem 0.5rem;
    vertical-align: baseline;
    margin-right: 0.25rem;
    border: 1px solid #8b4513;
    background-color: transparent;
}

.hint-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(139, 69, 19, 0.5);
    font-size: 1rem;
    pointer-events: none;
    padding: 0.2rem 0.5rem;
    line-height: 1.5;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 0.8);
}

.word-span {
    display: inline-block;
    margin: 0 0.25rem;
    padding: 0.2rem 0;
}

.word-input:disabled {
    background-color: #90EE90;
    color: #000;
}
